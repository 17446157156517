* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.generate-token {
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.generate-title {
  max-width: 80%;
}

.btn-download {
  background-color: #98c0a8;
  border-color: #72a386;
  color: black;
  font-weight: bold;
  font-size: 13px;
  min-width: auto !important;
  max-width: 80%;
}

.btn-download:hover {
  background-color: #72a386;
  border-color: #98c0a8;
  color: black;
}

.generate-alert {
  display: flex;
  justify-content: space-around;
  background-color: #e5e5e5;
  width: 50vw;
  height: 40vh;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
  font-size: smaller;
}

.token-info {
  max-width: 40vw;
}

@media (max-width: 740px) {
  .btn.btn-download {
    font-size: 13px !important;
  }
}


