* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: url("../../../public/Rectangle.png");
    background-size: 60% 100%;
    background-repeat: no-repeat;
    position: relative;

}

.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.login-image {
    transform: translateY(-10%);
    margin-right: 95px;
}

.login-container {
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn {
    margin-bottom: 10px;
    min-width: 260px;
    height: 50px;
    font-size: 20px !important;
    color: black;
}

@media ( max-width: 768px ) {
    .login-container {
        margin-right: 0;
    }

    .login-image {
        width: 180px;
        height: 180px;
        margin-right: 0px;
    }

    .btn.btn-login {
        min-width: auto;
        font-size: 15px !important;
    }
}