.nav-logo {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
}

.nav-btn{
    font-weight: bold;
    font-size: smaller;
}

.bg-white{
    border-bottom: 3px solid black;
    padding: 0;
}

.navbar-nav .btn-logout{
    min-width: 50px !important;
    height: 40px !important;
    font-weight: lighter;
    font-size: 16px !important;
    display: flex;
    border-color: black;
    background-color: white;
    align-items: center;
}

.navbar-nav .btn-logout:hover{
    border-color: white;
    background-color: black;
    color: white;
}