.root{
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: white;
    opacity: 0.8;
}
.progress {
    display: inline-block;
    margin-top: 200px;
}