* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.download-page {
  background-color: white;
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btn {
  background-color: #bfcef5;
  border-color: #95a9db;
  color: black;
  font-weight: bold;
  font-size: small;
}
.btn:hover {
  background-color: #95a9db;
  border-color: #bfcef5;
  color: black;
}

.btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.dropdown-list {
  width: 50vw;
  margin-top: 5px;
  margin-bottom: 10px;
}

.download-page > .btn {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.header-download {
  margin-top: 20px;
  font-weight: bold;
}

tr > td,
th {
  text-align: center;
}

.h-20rem {
  max-height: 21.15rem;
}
.scrollable {
  display: block;
  overflow-y: scroll
}
tr {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}

.notfoundData{
    text-align: center;
}

.errorMessage{
  margin-top: 10px;
}
.select-ad{
width: 50%;
margin-bottom: 250px;
}
